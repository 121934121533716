<template>
  <div class="sys-config-page">
    <div class="sys-config-page-conten">
      <fm-title title-text="机构配置"></fm-title>
      <fm-form style="margin:0 2rem 0 10px;" :inline="false" label-align="left">
        <fm-form-item label="机构名称">
          <fm-input-new @change="valueChange" v-model="orgInfo.name" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="机构等级">
          <fm-select @change="valueChange" absolute filterable v-model="orgInfo.level">
            <fm-option v-for="item in levelList" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="单位所属层级">
          <fm-select @change="valueChange" absolute filterable v-model="orgInfo.areaLevel">
            <fm-option v-for="item in areaLevelList" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="机构地址">
          <fm-input-new @change="valueChange" v-model="orgInfo.arrress" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="机构法人">
          <fm-input-new @change="valueChange" v-model="orgInfo.fr" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="机构邮编">
          <fm-input-new @change="valueChange" v-model="orgInfo.yb" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="辖区年度妇女儿童总人数">
          <fm-input-new @change="valueChange" v-model="orgInfo.fnetTotal" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="辖区母婴保健技术服务资质人员总数">
          <fm-input-new @change="valueChange" v-model="orgInfo.jsryTotal" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="乡镇和村级妇幼保健专干人数">
          <fm-input-new @change="valueChange" v-model="orgInfo.zgTotal" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="中级、高级技术职称卫生技术人员总数">
          <fm-input-new @change="valueChange" v-model="orgInfo.zgjTotal" placeholder=""/>
        </fm-form-item>
      </fm-form>
    </div>
  </div>
</template>

<script>
import {
  sysConfigRequest
} from '@/api'

export default {
  data () {
    return {
      levelList: ['三级甲等', '三级特等', '三级乙等', '三级丙等', '二级甲等', '二级乙等', '二级丙等', '一级甲等', '一级乙等', '一级丙等'],
      areaLevelList: ['中央', '省（区、市）', '地（市、州、盟）', '县（市、区、旗）', '乡（镇）'],
      sData: null,
      loading: true,
      orgInfo: {
        name: '',
        level: '',
        arrress: '',
        fr: '',
        yb: '',
        fnetTotal: '',
        jsryTotal: '',
        zgTotal: '',
        zgjTotal: '',
        areaLevel: ''
      }
    }
  },
  methods: {
    async loadData () {
      let res = await sysConfigRequest.get({type: 'sysConfig'})
      res = res.filter(v => v.configKey === 'org_info')
      if (res && res.length > 0) {
        let value = res[0].value ? JSON.parse(res[0].value) : ''
        this.sData = res[0].value
        if (value) {
          Object.keys(this.orgInfo).forEach(key => {
            this.orgInfo[key] = value[key]
          })
        }
      }
    },
    async valueChange () {
      if (this.loading) {
        return
      }
      let pass = true
      Object.keys(this.orgInfo).forEach(key => {
        if (key.includes('Total') && this.orgInfo[key] && isNaN(this.orgInfo[key])) {
          this.orgInfo[key] = ''
          pass = false
        }
      })
      if (!pass) {
        this.$notice.info({
          title: '系统提示',
          desc: '人数请输入数值。'
        })
        return
      }
      if (this.sData === JSON.stringify(this.orgInfo)) {
        return
      }
      await sysConfigRequest.add({
        type: 'sysConfig',
        configKey: 'org_info',
        name: '',
        remark: '',
        value: JSON.stringify(this.orgInfo)
      })
      this.loadData()
    }
  },
  created () {
    this.loadData()
    let vm = this
    setTimeout(() => {
      vm.loading = false
    }, 1000)
  }
}
</script>

<style scoped lang="less">
.sys-config-page {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .sys-config-page-conten {
    margin: 20px;
    background: #fff;
    border-radius: 8px;
    padding-bottom: 20px;
  }
}
.config-item {
  min-height: 5rem;
  border-bottom: 1px solid #eee;
}
.config-item {
  display: flex;
  min-height: 3rem;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  .c-label {
    width: 15rem;
    display: flex;
    align-items: center;
  }
  .c-remark {
    flex: 3;
    display: flex;
    align-items: center;
  }
  .c-value {
    width: 15rem;
    display: flex;
    margin-left: 2rem;
    align-items: center;
  }
}
</style>